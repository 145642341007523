






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { roles } from "@/constants/users";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "disabled",
          "sort-desc": false,
        },
        content: {
          name: "Users Management",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              roles: {
                type: "select",
                attrs: {
                  label: "Roles",
                  items: [{ value: "", text: "All" }].concat(
                    makeSelectOptions(roles)
                  ),
                },
                rules: {
                  equal_to: {},
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: [
                    { text: "Status", value: false },
                    { text: "Locked", value: true },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            index: {
              text: "No.",
              options: {
                index: true,
              },
            },
            email: {
              sortable: true,
            },
            name: {
              text: "Name",
              sortable: true,
            },
            description: {
              text: "Description",
              sortable: true,
              hidden: true,
            },
            roles: {
              text: "Roles",
              options: {
                labels: true,
                labelItems(value) {
                  return roles[value];
                },
              },
            },
            disabled: {
              text: "Status",
              options: {
                label(value) {
                  return (value && "Locked") || "Active";
                },
                attrs(value) {
                  return {
                    color: value ? "red" : "green",
                  };
                },
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {
              text: "Action",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["admin"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "email",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.orderItems = [];
            if (options.orderBy) {
              options.orderItems.push({
                field: options.orderBy,
                type: options.orderType,
              });
              options.orderBy = undefined;
              options.orderType = undefined;
            }
            options.orderItems.push({
              field: "disabled",
              type: "asc",
            });
            options.orderItems.push({
              field: "createdTime",
              type: "desc",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "users",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "users",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "users",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("users", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              email: {
                type: "email",
                attrs: {
                  required: true,
                },
              },
              name: {
                attrs: {
                  label: "Name",
                  required: true,
                },
              },
              roles: {
                type: "selectMultiple",
                attrs: {
                  label: "Roles",
                  items: makeSelectOptions(roles),
                  required: true,
                },
                content: {
                  rules: {
                    custom: [
                      async (value) => {
                        return value && value.length;
                      },
                    ],
                  },
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: [
                    { text: "Active", value: false },
                    { text: "Locked", value: true },
                  ],
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              password: {
                type: "password",
                attrs: {
                  label: "Password",
                  rules: [
                    (value) =>
                      !value || value.length >= 6 || "Min 6 characters",
                  ],
                },
              },
            },
            insertForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "email",
                  "name",
                  "roles",
                  "disabled",
                  "description",
                  "password",
                ],
              },
            },
            editForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "email",
                  "name",
                  "roles",
                  "disabled",
                  "description",
                  "password",
                ],
              },
            },
            delete: {
              confirmDisplayField: "email",
            },
          },
        },
      },
    };
  },
});
